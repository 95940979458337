<template>
  <div class="v-stack gap-3 h-stretch" v-if="loan">
    <div ref="overlay" class="overlay">
      <div class="card dynamic">
        <div class="content mt-3">
          <div class="v-stack h-stretch gap-5">
            <div>Are you sure you want to mark the loan as paid?</div>
          </div>
        </div>
        <div class="footer gap-3 h-space-between mt-3">
          <button @click="dismissPaidDialog()" class="submit">Cancel</button>
          <button @click="markLoanAsPaidDialog()" class="edit">Submit</button>
        </div>
      </div>
    </div>
    <div class="h-stack h-space-between">
      <a class="heading-title-2">Info</a>
      <button v-if="loan.state == 'signed' && $store.state.accessLevel > 9" class="submit"
        @click="showPaidDialog()">Mark as Paid</button>
    </div>
    <div class="card light mn">
      <div class="content h-stretch">
        <div class="v-stack gap-3 h-stretch">
          <label class="text-left">Amount</label>
          <div class="text-left">{{ format.currency(loan.amount) }} kč</div>
          <label class="text-left">Monthly</label>
          <div class="text-left">
            {{ format.currency(loan.monthlyRate) }} kč
          </div>
          <label class="text-left">Paid</label>
          <div class="text-left">
            {{ format.currency(loanObject.amountPaid(loan)) }} kč
          </div>
          <label class="text-left">Left To Pay</label>
          <div class="text-left">
            {{ format.currency(loanObject.leftToPay(loan)) }} kč
          </div>
          <label class="text-left">Description</label>
          <textarea class="text-left" v-model="loan.description" disabled></textarea>
          <label class="text-left">State</label>
          <div class="text-left">{{ loan.state }}</div>
          <label class="text-left">Months left</label>
          <div class="text-left">
            {{ loanObject.predictedEndDate(loan).duration }}
          </div>
          <label class="text-left">Predicted End Date</label>
          <div class="text-left">
            {{
                formatDate(
                  loanObject.predictedEndDate(loan).endDate,
                  "DD.MM.YYYY"
                )
            }}
          </div>
        </div>
      </div>
    </div>
    <a class="heading-title-2">Payments</a>
    <div class="h-stack h-end" v-if="loan.state == 'signed' && loanObject.leftToPay(loan) > 0">
      <button class="add" @click="$router.push('/loans/pay/' + id)">
        New Payment
      </button>
    </div>
    <Table :items="loan.onetimepay" :defaultColumn="0" :columns="[
      {
        name: 'Date',
        sort: 'date',
        path: ['date'],
        formatType: 'date',
        format: 'DD.MM.YYYY',
      },
      {
        name: 'Amount',
        sort: 'numeric',
        path: ['amount'],
        formatMap: (value) => Math.abs(value),
        formatType: 'currency',
        format: 'czk',
      },
      {
        name: 'State',
        sort: 'alphabet',
        path: ['state'],
      },
    ]"></Table>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Table from "@/components/Table.vue";
import utils from "@/utils.js";
import moment from "moment";

export default {
  props: ["id"],
  components: {
    Table,
  },
  data() {
    return {
      loan: null,
      loanObject: utils.loanObject,
      format: utils.format,
    };
  },
  methods: {
    ...mapActions(["getLoan", "markLoanAsPaid"]),
    dismissPaidDialog() {
      this.$refs.overlay.classList.remove("show");
    },
    markLoanAsPaidDialog() {
      this.markLoanAsPaid(this.loan._id).then(() => {
        this.refresh()
      }).catch((error) => {
        console.log(error)
      })
      this.dismissPaidDialog()
    },
    showPaidDialog() {
      this.$refs.overlay.classList.add("show");
    },
    formatDate(dateString, format) {
      if (dateString) {
        const date = moment(dateString);
        return date.format(format);
      } else {
        return null;
      }
    },
    refresh() {
      this.getLoan(this.id)
        .then((loan) => {
          this.loan = loan;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  mounted() {
    this.refresh()
  },
};
</script>
